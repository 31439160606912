































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import LoadingDataCard from '@/vue-app/components/home-dashboard/LoadingDataCard.vue';
import LinkedRetirementCardViewModel from '@/vue-app/view-models/components/goals-dashboard/linked-retirement-card-view-model';

@Component({
  name: 'LinkedRetirementFundCard',
  components: {
    LoadingDataCard,
    DetailsRetirementFundModal: () => import('./details-goals/DetailsRetirementFundModal.vue'),
    EditRetirementFundGoalPlanDialog: () => import('./edit-plan-goals/edit-retirement-fund-goal-plan/EditRetirementFundGoalPlanDialog.vue'),
    GoalTrackingTag: () => import('@/vue-app/components/goals-dashboard/GoalTrackingTag.vue'),
    CustomTooltip: () => import('@/vue-app/components/custom/CustomTooltip.vue'),
    WealthContributionDialog: () => import('@/vue-app/components/goals-dashboard/contribute-goals/wealth/WealthContributionDialog.vue'),
  },
})
export default class LinkedRetirementFundCard extends Vue {
  linked_retirement_fund_view_model = Vue.observable(new LinkedRetirementCardViewModel(this));

  @Watch('linked_retirement_fund_view_model.distribute_state.distributed')
  handleDistributeChange(loaded: boolean) {
    if (loaded) {
      this.linked_retirement_fund_view_model.handleDistributeChange();
    }
  }

  @Watch('linked_retirement_fund_view_model.manage_tracking_investor_goal_state.show_pause_tracking_reason_dialog')
  handleShowPauseTrackingReasonDialog(show: boolean) {
    if (show) {
      this.linked_retirement_fund_view_model.handleShowPauseTrackingReasonDialog();
    }
  }

  @Watch('linked_retirement_fund_view_model.manage_tracking_investor_goal_state.show_tracking_resumed_dialog')
  handleShowTrackingResumedDialog(show: boolean) {
    if (show) {
      this.linked_retirement_fund_view_model.handleShowTrackingResumedDialog();
    }
  }

  created() {
    this.linked_retirement_fund_view_model.initialize();
  }
}
