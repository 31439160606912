














































































































































































































































import { Component, PropSync, Vue } from 'vue-property-decorator';
import DetailsCustomModalViewModel
  from '@/vue-app/view-models/components/goals-dashboard/details-goals/details-custom-modal-view-model';
import {
  InvestmentProductFundTypesEntity,
} from '@/modules/flagship/investor-goal/search-by-customer/domain/entities/search-by-customer-entity';

type CustomGoalInformation = {
  investor_goal_id: string;
  is_custom_goal_pocket: boolean;
  name: string;
  icon_name: string;
  strategy_moderate_is_available: boolean;
  initial_amount: number;
}

@Component({
  name: 'DetailsCustomModal',
  components: {
    ContractingStatusModerateDialog: () => import('@/vue-app/components/strategy-moderate-wealth/contracting-status-moderate/ContractingStatusModerateDialog.vue'),
    LinkStatusModerateDialog: () => import('@/vue-app/components/strategy-moderate-wealth/link-status-moderate/LinkStatusModerateDialog.vue'),
    CustomTooltip: () => import('@/vue-app/components/custom/CustomTooltip.vue'),
    CustomProgressBar: () => import('@/vue-app/components/goals-dashboard/details-goals/CustomProgressBar.vue'),
  },
})
export default class DetailsCustomModal extends Vue {
  @PropSync('isOpen', { type: Boolean, required: true })
  synced_is_open!: boolean;

  @PropSync('customGoalInformation', { type: Object, required: true })
  custom_goal_information!: CustomGoalInformation;

  @PropSync('strategyOption', { type: String, required: true })
  strategy_option!: string;

  @PropSync('investmentProductFundTypes', { type: Object, required: true })
  investment_product_fund_types!: InvestmentProductFundTypesEntity;

  @PropSync('customIconFileId', { type: String, required: true })
  custom_icon_file_id!: string;

  @PropSync('customGoalUpdatedAtTimeStamp', { type: Number, required: true })
  custom_goal_updated_at_time_stamp!: number;

  @PropSync('showStopTrackingGoal', { type: Boolean, required: true })
  show_stop_tracking_goal!: boolean;

  @PropSync('showResumeTrackingGoal', { type: Boolean, required: true })
  show_resume_tracking_goal!: boolean;

  details_custom_modal_view_model = Vue.observable(new DetailsCustomModalViewModel(this));

  created() {
    this.details_custom_modal_view_model.initialize(
      this.custom_goal_information,
      this.strategy_option,
      this.investment_product_fund_types,
      this.custom_icon_file_id,
      this.custom_goal_updated_at_time_stamp,
    );
  }

  closeModal() {
    this.synced_is_open = false;
  }

  upgradeToModerate() {
    this.synced_is_open = false;
    this.details_custom_modal_view_model.upgradeToModerate();
  }

  modifyStrategy() {
    this.synced_is_open = false;
    this.details_custom_modal_view_model.modifyStrategy();
  }

  pauseTracking() {
    this.closeModal();
    this.details_custom_modal_view_model.pauseTracking();
  }

  resumeTracking() {
    this.closeModal();
    this.details_custom_modal_view_model.resumeTracking();
  }
}
