import TYPES from '@/modules/shared/types';

// Domain
import {
  InvestorGoalMovementsRepository,
} from '../../domain/repositories/investor-goal-movements-repository';
import {
  InvestorGoalMovementEntity,
} from '../../domain/entities/investor-goal-movement-entity';
import {
  GetInvestorGoalMovementsQueryDto,
} from '../../domain/dtos/get-investor-goal-movements-query-dto';
import {
  ActiveGoalAmountByFundTypeEntity,
} from '@/modules/flagship/investor-goal/search-by-customer/domain/entities/active-goal-amount-by-fund-type-entity';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import HttpConnector from '@/modules/shared/domain/connectors/http';

@Injectable()
export default class InvestorGoalMovementsHttpRepository
implements InvestorGoalMovementsRepository {
  @Inject(TYPES.HTTP_CONNECTOR)
  private readonly http_connector!: HttpConnector;

  private endpoint = `${process.env.VUE_APP_API_URL}/api/v1/app/flagship/investor-goal/{investor_goal_id}/movements/`;

  // eslint-disable-next-line max-len
  get_movements(query: GetInvestorGoalMovementsQueryDto): Promise<Array<InvestorGoalMovementEntity>> {
    return new Promise((resolve, reject) => {
      const params = new URLSearchParams({
        from_date: query.from_date,
        to_date: query.to_date,
        movement_type_code: query.movement_type_code,
      });
      this.http_connector.execute(`${this.endpoint}?${params}`.replace('{investor_goal_id}', query.investor_goal_id))
        .then((response) => {
          response.json()
            .then(({ success, data, message }) => {
              if (success) {
                resolve(data);
              } else {
                reject(JSON.stringify({
                  status_code: response.status,
                  message_error: message,
                }));
              }
            });
        })
        .catch(reject);
    });
  }
}
