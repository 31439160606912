






















































































































































import { Component, PropSync, Vue } from 'vue-property-decorator';
import InvestorGoalMovementsViewModel
  from '@/vue-app/view-models/components/goals-dashboard/linked-goals/investor-goal-movements-view-model';

@Component({
  name: 'InvestorGoalMovementsDialog',
  components: {
    RangePicker: () => import('@/vue-app/components/RangePicker.vue'),
    CustomTooltip: () => import('@/vue-app/components/custom/CustomTooltip.vue'),
  },
})
export default class InvestorGoalMovementsDialog extends Vue {
  @PropSync('isOpen', { type: Boolean, required: true })
  synced_is_open!: boolean;

  view_model = Vue.observable(new InvestorGoalMovementsViewModel(this));

  created() {
    this.view_model.initialize();
  }

  closeModal() {
    this.synced_is_open = false;
  }
}
