import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import InvestorGoalMovementsHttpRepository
  from './infrastructure/repositories/investor-goal-movements-http-repository';

// Application
import GetInvestorGoalMovementsQuery
  from './application/queries/get-investor-goal-movements-query';

// Domain
import { InvestorGoalMovementsRepository } from './domain/repositories/investor-goal-movements-repository';

export default function setupInvestorGoalMovements(container: Container) {
  container
    .bind<InvestorGoalMovementsRepository>(TYPES.INVESTOR_GOAL_MOVEMENTS_REPOSITORY)
    .to(InvestorGoalMovementsHttpRepository)
    .inSingletonScope();

  container
    .bind<GetInvestorGoalMovementsQuery>(TYPES.GET_INVESTOR_GOAL_MOVEMENTS_QUERY)
    .to(GetInvestorGoalMovementsQuery)
    .inSingletonScope();
}
