















































































































































































import { Component, PropSync, Vue } from 'vue-property-decorator';
import DetailsEmergencyFundModalViewModel
  from '@/vue-app/view-models/components/goals-dashboard/details-goals/details-emergency-fund-modal-view-model';

@Component({
  name: 'DetailsEmergencyFundModal',
  components: {
    CustomTooltip: () => import('@/vue-app/components/custom/CustomTooltip.vue'),
  },
})
export default class DetailsEmergencyFundModal extends Vue {
  @PropSync('isOpen', { type: Boolean, required: true })
  synced_is_open!: boolean;

  @PropSync('investorGoalId', { type: String, required: true })
  investor_goal_id!: string;

  @PropSync('showStopTrackingGoal', { type: Boolean, required: true })
  show_stop_tracking_goal!: boolean;

  @PropSync('showResumeTrackingGoal', { type: Boolean, required: true })
  show_resume_tracking_goal!: boolean;

  details_emergency_fund_view_model = Vue.observable(new DetailsEmergencyFundModalViewModel());

  created() {
    this.details_emergency_fund_view_model.initialize(this.investor_goal_id);
  }

  closeModal() {
    this.synced_is_open = false;
  }

  pauseTracking() {
    this.closeModal();
    this.$emit('pauseTracking');
  }

  resumeTracking() {
    this.closeModal();
    this.$emit('resumeTracking');
  }

  showGoalMovements = () => {
    this.$emit('showGoalMovements');
  }
}
