import TYPES from '@/types';
import { currencyFormat } from '@/vue-app/utils/currency';

// Application
import GetEmergencyFundInvestorGoalQuery from '@/modules/flagship/emergency-fund-investor-goal/application/query/get-emergency-fund-investor-goal-query';
import GetCustomerInvestorGoalReminderQuery
  from '@/modules/flagship/customer-investor-goal-reminder/application/queries/get-customer-investor-goal-reminder-query';
import GetGoalTrackingQuery
  from '@/modules/flagship/investor-goal/goal-tracking/application/queries/get-goal-tracking-query';
import SetGoalTrackingCommand
  from '@/modules/flagship/investor-goal/goal-tracking/application/commands/set-goal-tracking-command';

// Domain
import {
  CreateInvestorGoalStateManager,
} from '@/modules/flagship/investor-goal/investor_goal/domain/state/create-investor-goal-state-manager';
import {
  GoalsDashboardDistributeStateManager,
} from '@/modules/goals-dashboard/distribute-balance/domain/state/goals-dashboard-distribute-state-manager';
import {
  ManageTrackingInvestorGoalStateManager,
} from '@/modules/flagship/investor-goal/investor_goal/domain/state/manage-tracking-investor-goal-state-manager';
import Inject from '@/modules/shared/domain/di/inject';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';
import { DateFormatter } from '@/modules/shared/domain/date-formatters';

export default class LinkedEmergencyFundCardViewModel {
  @Inject(TYPES.I18N)
  private readonly translator!: Translator;

  @Inject(TYPES.GET_EMERGENCY_FUND_INVESTOR_GOAL_QUERY)
  private readonly get_emergency_fund_goal_query!: GetEmergencyFundInvestorGoalQuery;

  @Inject(TYPES.GET_CUSTOMER_INVESTOR_GOAL_REMINDER_QUERY)
  private readonly get_customer_investor_goal_reminder_query!: GetCustomerInvestorGoalReminderQuery;

  @Inject(TYPES.GET_GOAL_TRACKING_QUERY)
  private readonly get_goal_tracking_query!: GetGoalTrackingQuery;

  @Inject(TYPES.SET_GOAL_TRACKING_COMMAND)
  private readonly set_goal_tracking_command!: SetGoalTrackingCommand;

  @Inject(TYPES.CREATE_INVESTOR_GOAL_STATE_MANAGER)
  readonly create_investor_goal_state_manager!: CreateInvestorGoalStateManager;

  @Inject(TYPES.GOALS_DASHBOARD_DISTRIBUTE_STATE_MANAGER)
  private readonly distribute_state_manager!: GoalsDashboardDistributeStateManager;

  @Inject(TYPES.MANAGE_TRACKING_INVESTOR_GOAL_STATE_MANAGER)
  readonly manage_tracking_investor_goal_state_manager!: ManageTrackingInvestorGoalStateManager;

  @Inject(TYPES.NOTIFIER)
  private readonly message_notifier!: MessageNotifier;

  @Inject(TYPES.DATE_FORMATTER)
  readonly date_formatter!: DateFormatter;

  readonly i18n_namespace = 'components.goals-dashboard.linked-emergency-fund-card';

  is_loading = false;

  is_active = false;

  progress = 0;

  active_goal_amount = 0;

  accumulated_amount = 0;

  initial_investment_date = '';

  final_investment_date = '';

  last_edition_date = '';

  fixed_time_adjusted = 0;

  monthly_required_amount = 0;

  desired_amount = 0;

  details_card = {
    is_open: true,
  };

  show_details_modal = false;

  investor_goal_id = '';

  day_to_remind = 0;

  is_subscribed = false;

  associated_product_id = '';

  strategy_label = '';

  show_edit_plan_dialog = false;

  section_plan_dates_cols = 6;

  tracking_percent = 0;

  tracking_enabled = false;

  readonly limit_for_allow_stop_tracking = 40;

  show_contribute_dialog = false;

  distribute_state = this.distribute_state_manager.state;

  manage_tracking_investor_goal_state = this
    .manage_tracking_investor_goal_state_manager.state;

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  showDetailsModal = () => {
    this.show_details_modal = true;
  }

  showEditPlanDialog = () => {
    this.show_edit_plan_dialog = true;
  }

  showContributeDialog = () => {
    this.show_contribute_dialog = true;
  }

  initialize = async () => {
    this.strategy_label = this.translate('strategies.pesos');
    await this.loadEmergencyFundInvestorGoalInformation();
    await this.loadCustomerInvestorGoalReminder();
  }

  get show_stop_tracking_goal() {
    return this.tracking_percent <= this.limit_for_allow_stop_tracking && this.tracking_enabled;
  }

  get show_resume_tracking_goal() {
    return !this.tracking_enabled;
  }

  get start_date_formatted() {
    return (this.initial_investment_date) ? this.date_formatter.formatDate(this.initial_investment_date, 'DD/MM/YYYY') : this.initial_investment_date;
  }

  get last_edition_date_formatted() {
    return (this.last_edition_date) ? this.date_formatter.formatDate(this.last_edition_date, 'DD/MM/YYYY') : this.last_edition_date;
  }

  get final_date_formatted() {
    return (this.final_investment_date) ? this.date_formatter.formatDate(this.final_investment_date, 'DD/MM/YYYY') : this.final_investment_date;
  }

  getAmountFormatted(amount: number) {
    return `${currencyFormat(amount)} MXN`;
  }

  get progress_formatted() {
    return this.progress.toFixed(2);
  }

  loadEmergencyFundInvestorGoalInformation = async () => {
    try {
      this.is_loading = true;
      const emergency_fund = await this.get_emergency_fund_goal_query.execute();

      this.fixed_time_adjusted = emergency_fund.fixed_time_adjusted;
      if (emergency_fund.investor_goal) {
        this.investor_goal_id = emergency_fund.investor_goal_id;
        this.is_active = emergency_fund.investor_goal.is_active;
        this.active_goal_amount = emergency_fund.investor_goal.active_goal_amount!;
        this.accumulated_amount = Math.round(emergency_fund.investor_goal.accumulated_amount);
        this.monthly_required_amount = emergency_fund.investor_goal.monthly_required_amount;
        this.desired_amount = (emergency_fund.desired_amount) ? emergency_fund.desired_amount : 0;
        // eslint-disable-next-line max-len
        this.progress = (this.active_goal_amount / this.accumulated_amount) * 100;
        this.initial_investment_date = emergency_fund.investor_goal.initial_investment_date;
        this.final_investment_date = emergency_fund.investor_goal.final_investment_date;
        this.associated_product_id = emergency_fund.investor_goal.associated_product_id;
        if (emergency_fund.first_initial_investment_date) {
          this.section_plan_dates_cols = 4;
          this.initial_investment_date = emergency_fund.first_initial_investment_date;
          this.last_edition_date = emergency_fund.investor_goal.initial_investment_date;
        }
        this.loadProgressGoalInformation(emergency_fund.tracking_percent);
        this.tracking_enabled = emergency_fund.investor_goal.tracking_enabled;
        this.create_investor_goal_state_manager.state.emergency_fund.already_created = true;
      }
    } catch (error) {
      if (JSON.parse(error).status_code !== 404) {
        this.message_notifier.showErrorNotification(this.translate('errors.load_emergency_fund_information'));
      }
    } finally {
      this.is_loading = false;
    }
  }

  loadCustomerInvestorGoalReminder = async () => {
    try {
      // eslint-disable-next-line max-len
      const customer_investor_goal_reminder = await this.get_customer_investor_goal_reminder_query.execute({
        associated_product_id: this.associated_product_id,
      });
      if (customer_investor_goal_reminder) {
        this.day_to_remind = customer_investor_goal_reminder.day_to_remind;
        this.is_subscribed = customer_investor_goal_reminder.is_subscribed || false;
      }
    } catch (error) {
      if (JSON.parse(error).status_code !== 404) {
        this.message_notifier.showErrorNotification(this.translate('errors.get_customer_investor_goal_reminder'));
      }
    }
  };

  loadProgressGoalInformation = (tracking_percent: number) => {
    this.tracking_percent = (tracking_percent > 100)
      ? 100 : Number(tracking_percent.toFixed(0));
  }

  requestDeposit = () => {
    this.show_contribute_dialog = true;
  }

  closeModalDepositAccountInfo = () => {
    this.show_contribute_dialog = false;
  }

  handleDistributeChange = async () => {
    await this.loadEmergencyFundInvestorGoalInformation();
  }

  pauseTrackingGoal = async () => {
    this.manage_tracking_investor_goal_state.investor_goal_id = this.investor_goal_id;
    this.manage_tracking_investor_goal_state.investor_goal_name = this.translate('title');
    this.manage_tracking_investor_goal_state.show_pause_tracking_dialog = true;
  }

  resumeTrackingGoal = async () => {
    this.manage_tracking_investor_goal_state.investor_goal_id = this.investor_goal_id;
    this.manage_tracking_investor_goal_state.investor_goal_name = this.translate('title');
    this.manage_tracking_investor_goal_state.show_resume_tracking_dialog = true;
  }

  showGoalMovements = () => {
    this.manage_tracking_investor_goal_state.investor_goal_id = this.investor_goal_id;
    this.manage_tracking_investor_goal_state.show_goal_movements = true;
  }

  handleShowPauseTrackingReasonDialog = () => {
    if (this.manage_tracking_investor_goal_state.investor_goal_id === this.investor_goal_id) {
      this.tracking_enabled = false;
    }
  }

  handleShowTrackingResumedDialog = () => {
    if (this.manage_tracking_investor_goal_state.investor_goal_id === this.investor_goal_id) {
      this.tracking_enabled = true;
    }
  }
}
