import TYPES from '../../types';

// Domain
import {
  InvestorGoalMovementsRepository,
} from '../../domain/repositories/investor-goal-movements-repository';
import {
  InvestorGoalMovementEntity,
} from '../../domain/entities/investor-goal-movement-entity';
import { GetInvestorGoalMovementsQueryDto } from '../../domain/dtos/get-investor-goal-movements-query-dto';
import Injectable from '@/modules/shared/domain/di/injectable';
import Query from '@/modules/shared/domain/use-cases/query';
import Inject from '@/modules/shared/domain/di/inject';

@Injectable()
// eslint-disable-next-line max-len
export default class GetInvestorGoalMovementsQuery extends Query<Promise<Array<InvestorGoalMovementEntity>>> {
  @Inject(TYPES.INVESTOR_GOAL_MOVEMENTS_REPOSITORY)
  private readonly repository!: InvestorGoalMovementsRepository;

  internalExecute(search_by_customer_dto: GetInvestorGoalMovementsQueryDto):
    Promise<Array<InvestorGoalMovementEntity>> {
    return new Promise((resolve, reject) => {
      this.repository.get_movements(search_by_customer_dto)
        .then((items) => {
          resolve(items);
        })
        .catch(reject);
    });
  }
}
