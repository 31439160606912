import TYPES from '@/types';
import { currencyFormat } from '@/vue-app/utils/currency';

import Vue from 'vue';

// Application
import GetRetirementInvestorGoalQuery from '@/modules/flagship/retirement-investor-goal/application/queries/get-retirement-investor-goal-query';
import {
  GetAllianzAccountQuery,
} from '@/modules/my-investment/allianz/allianz-account/application/queries';
import GetRetirementInvestorGoalCalculatorQuery
  from '@/modules/flagship/retirement-investor-goal-calculator/application/queries/get-retirement-investor-goal-calculator-query';
import GetPersonalInfoQuery
  from '@/modules/flagship/investor-profile/personal-info/application/queries/get-personal-info-query';
import LinkedGoalStateManagerService
  from '@/modules/flagship/investor-goal/linked-investor-goal/application/services/linked-goal-state-manager-service';

// Domain
import {
  verifyIfInvestmentProductFundTypeIsLinked,
} from '@/modules/flagship/customer-investment-product-fund-type/domain/services/verify-if-investment-product-fund-type-is-linked';
import {
  RetirementInvestorGoalDto,
} from '@/modules/flagship/retirement-investor-goal/domain/dtos/retirement-investor-goal-dto';
import {
  RetirementInvestorGoalCalculatorDto,
} from '@/modules/flagship/retirement-investor-goal-calculator/domain/dtos/retirement-investor-goal-calculator-dto';
import {
  CreateInvestorGoalStateManager,
} from '@/modules/flagship/investor-goal/investor_goal/domain/state/create-investor-goal-state-manager';
import {
  GoalsDashboardDistributeStateManager,
} from '@/modules/goals-dashboard/distribute-balance/domain/state/goals-dashboard-distribute-state-manager';
import {
  ManageTrackingInvestorGoalStateManager,
} from '@/modules/flagship/investor-goal/investor_goal/domain/state/manage-tracking-investor-goal-state-manager';
import Inject from '@/modules/shared/domain/di/inject';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';
import { DateFormatter } from '@/modules/shared/domain/date-formatters';

export default class LinkedRetirementCardViewModel {
  @Inject(TYPES.I18N)
  private readonly translator!: Translator;

  @Inject(TYPES.GET_RETIREMENT_INVESTOR_GOAL_QUERY)
  private readonly get_retirement_investor_goal_query!: GetRetirementInvestorGoalQuery;

  @Inject(TYPES.GET_ALLIANZ_ACCOUNT_QUERY)
  private readonly get_allianz_account_query!: GetAllianzAccountQuery;

  @Inject(TYPES.GET_PERSONAL_INFO_QUERY)
  private readonly get_personal_info_query!: GetPersonalInfoQuery;

  @Inject(TYPES.GET_RETIREMENT_INVESTOR_GOAL_CALCULATOR_QUERY)
  private readonly get_retirement_investor_goal_calculator_query!:
    GetRetirementInvestorGoalCalculatorQuery;

  @Inject(TYPES.LINKED_GOAL_STATE_MANAGER_SERVICE)
  private readonly linked_goal_state_manager_service!:
    LinkedGoalStateManagerService;

  @Inject(TYPES.CREATE_INVESTOR_GOAL_STATE_MANAGER)
  readonly create_investor_goal_state_manager!: CreateInvestorGoalStateManager;

  @Inject(TYPES.GOALS_DASHBOARD_DISTRIBUTE_STATE_MANAGER)
  private readonly distribute_state_manager!: GoalsDashboardDistributeStateManager;

  @Inject(TYPES.MANAGE_TRACKING_INVESTOR_GOAL_STATE_MANAGER)
  readonly manage_tracking_investor_goal_state_manager!: ManageTrackingInvestorGoalStateManager;

  @Inject(TYPES.NOTIFIER)
  private readonly message_notifier!: MessageNotifier;

  @Inject(TYPES.DATE_FORMATTER)
  readonly date_formatter!: DateFormatter;

  readonly i18n_namespace = 'components.goals-dashboard.linked-retirement-fund-card';

  private readonly customer_id = sessionStorage.getItem('user_id');

  private readonly error_allianz_account_not_exists = `Error customer_id:${this.customer_id}, not exists`;

  private readonly MODERATE_FUND_NAME = 'SWS-MODERADO';

  readonly limit_for_allow_stop_tracking = 40;

  is_loading = false;

  retirement_investor_goal = {
    investor_goal_id: '',
    is_active: false,
    retirement_age_adjusted: 0,
    recurrent_assigned_amount: 0,
    monthly_pension_adjusted: 0,
    accumulated_amount: 0,
    active_goal_amount: 0,
    progress: 0,
    initial_investment_date: '',
    final_investment_date: '',
    strategy: 'pesos',
    investment_product_fund_types: {},
    last_edition_date: '',
    associated_product_id: '',
  }

  color_sws = {
    color_card: '',
    color_title: 'primary--text',
    color_subtitle: 'sky-blue--text',
    color_label: 'white--text',
    background_color_label: 'accent',
    color_divider: '',
    color_icon: 'sky-blue',
    color_btn: 'white--text',
    color_progress: 'accent',
  }

  details_card = {
    is_open: true,
  };

  show_details_modal = false;

  monthly_required_amount = 0;

  has_active_recurrent_contribution = false;

  show_edit_plan_dialog = false;

  section_plan_dates_cols = 6;

  tracking_percent = 0;

  monthly_pension_adjusted_formatted = '';

  show_contribute_dialog = false;

  distribute_state = this.distribute_state_manager.state;

  tracking_enabled = false;

  manage_tracking_investor_goal_state = this
    .manage_tracking_investor_goal_state_manager.state;

  readonly view: Vue;

  constructor(view: Vue) {
    this.view = view;
  }

  get show_stop_tracking_goal() {
    return this.tracking_percent <= this.limit_for_allow_stop_tracking && this.tracking_enabled;
  }

  get show_resume_tracking_goal() {
    return !this.tracking_enabled;
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  showDetailsModal = () => {
    this.show_details_modal = true;
  }

  showEditPlanDialog = () => {
    this.show_edit_plan_dialog = true;
  }

  showContributeDialog = () => {
    this.setLinkedInvestorGoalInformation();
    this.show_contribute_dialog = true;
  }

  initialize = async () => {
    await this.verifyFfTheUserHasTheRecurringContributionActive();
    await this.loadRetirementInvestorGoalInformation(true);
  }

  get start_date_formatted() {
    return (this.retirement_investor_goal.initial_investment_date)
      ? this.date_formatter.formatDate(this.retirement_investor_goal.initial_investment_date, 'DD/MM/YYYY')
      : this.retirement_investor_goal.initial_investment_date;
  }

  get final_date_formatted() {
    return (this.retirement_investor_goal.final_investment_date)
      ? this.date_formatter.formatDate(this.retirement_investor_goal.final_investment_date, 'DD/MM/YYYY')
      : this.retirement_investor_goal.final_investment_date;
  }

  get last_edition_date_formatted() {
    return (this.retirement_investor_goal.last_edition_date)
      ? this.date_formatter.formatDate(this.retirement_investor_goal.last_edition_date, 'DD/MM/YYYY')
      : this.retirement_investor_goal.last_edition_date;
  }

  getAmountFormatted(amount: number) {
    return currencyFormat(amount);
  }

  get progress_formatted() {
    return this.retirement_investor_goal.progress.toFixed(2);
  }

  get strategy_label() {
    return this.translate(this.retirement_investor_goal.strategy === 'pesos' ? 'strategies.pesos' : 'strategies.moderate');
  }

  changeColorSwsModerate = () => {
    this.color_sws.color_card = 'primary';
    this.color_sws.color_title = 'white--text';
    this.color_sws.color_subtitle = 'white--text';
    this.color_sws.color_label = 'primary--text';
    this.color_sws.background_color_label = 'white';
    this.color_sws.color_divider = 'white';
    this.color_sws.color_icon = 'white';
    this.color_sws.color_btn = 'accent--text';
    this.color_sws.color_progress = 'white';
  }

  validateIfHasContractedModerate = (retirement_investor_goal: RetirementInvestorGoalDto) => {
    this.retirement_investor_goal.investment_product_fund_types = retirement_investor_goal
      .investor_goal.investment_product_fund_types;
    if (retirement_investor_goal.investor_goal
      .investment_product_fund_types.linked) {
      const linked_investment_product_funds = retirement_investor_goal.investor_goal
        .investment_product_fund_types.linked;
      const goal_is_linked_to_moderate = verifyIfInvestmentProductFundTypeIsLinked(
        linked_investment_product_funds,
      );
      if (goal_is_linked_to_moderate) {
        this.retirement_investor_goal.strategy = 'moderate';
        this.changeColorSwsModerate();
      }
    }
  }

  onSpecialContributionDistributedReloadRetirementInvestorGoalInformation = async () => {
    await this.loadRetirementInvestorGoalInformation(true);
  }

  getCurrentAge = async (retirement_investor_goal: RetirementInvestorGoalDto) => {
    let current_age = 0;
    try {
      const { person } = await this.get_personal_info_query.execute(
        retirement_investor_goal.investor_goal.investor_profile_id,
      );
      current_age = person.current_age;
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.load_user_personal_information'));
    }
    return current_age;
  }

  calculateCurrentMonthlyPension = async (retirement_investor_goal: RetirementInvestorGoalDto) => {
    try {
      const current_age = await this.getCurrentAge(retirement_investor_goal);
      const get_pension_dto: RetirementInvestorGoalCalculatorDto = {
        associated_product_id: retirement_investor_goal.investor_goal.associated_product_id || '',
        defined_by: 'contribution',
        issued_age: current_age,
        retirement_age_adjusted: retirement_investor_goal.retirement_age_adjusted,
        initial_amount: retirement_investor_goal.investor_goal.active_goal_amount || 0,
        monthly_required_amount: 0,
        monthly_pension_adjusted: 0,
      };
      const { retirement_calculation_data } = await this
        .get_retirement_investor_goal_calculator_query.execute(get_pension_dto);
      this.monthly_pension_adjusted_formatted = `${currencyFormat(
        retirement_calculation_data.monthly_pension_adjusted,
      )} MXN`;
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.calculate_monthly_pension_adjusted'));
    }
  }

  loadRetirementInvestorGoalInformation = async (reload = false) => {
    try {
      this.is_loading = true;
      const retirement_investor_goal = await this.get_retirement_investor_goal_query.execute(
        reload,
      );
      this.validateIfHasContractedModerate(retirement_investor_goal);
      this.retirement_investor_goal.investor_goal_id = retirement_investor_goal.investor_goal_id;
      this.retirement_investor_goal.is_active = retirement_investor_goal.investor_goal.is_active!;
      this.retirement_investor_goal.associated_product_id = retirement_investor_goal
        .investor_goal.associated_product_id!;
      this.retirement_investor_goal.retirement_age_adjusted = retirement_investor_goal
        .retirement_age_adjusted;
      this.retirement_investor_goal.active_goal_amount = retirement_investor_goal
        .investor_goal.active_goal_amount!;
      this.retirement_investor_goal.monthly_pension_adjusted = retirement_investor_goal
        .monthly_pension_adjusted;
      this.retirement_investor_goal.recurrent_assigned_amount = (this
        .has_active_recurrent_contribution) ? retirement_investor_goal.investor_goal
          .recurrent_assigned_amount! : 0;
      this.retirement_investor_goal.accumulated_amount = Math.round(
        retirement_investor_goal.investor_goal.accumulated_amount!,
      );
      this.retirement_investor_goal.progress = (this.retirement_investor_goal.active_goal_amount
        / this.retirement_investor_goal.accumulated_amount) * 100;
      this.retirement_investor_goal.initial_investment_date = retirement_investor_goal
        .investor_goal.initial_investment_date!;
      this.retirement_investor_goal.final_investment_date = retirement_investor_goal
        .investor_goal.final_investment_date!;
      this.monthly_required_amount = retirement_investor_goal
        .investor_goal.monthly_required_amount!;
      if (retirement_investor_goal.first_initial_investment_date) {
        this.section_plan_dates_cols = 3;
        this.retirement_investor_goal.initial_investment_date = retirement_investor_goal
          .first_initial_investment_date;
        this.retirement_investor_goal.last_edition_date = retirement_investor_goal.investor_goal
          .initial_investment_date;
      }
      await this.calculateCurrentMonthlyPension(retirement_investor_goal);
      this.loadProgressGoalInformation(retirement_investor_goal.tracking_percent);
      this.create_investor_goal_state_manager.state.retirement_fund.already_created = true;
      this.tracking_enabled = retirement_investor_goal.investor_goal.tracking_enabled;
    } catch (error) {
      if (JSON.parse(error).status_code !== 404) {
        this.message_notifier.showErrorNotification(this.translate('errors.load_retirement_investor_goal_information'));
      }
    } finally {
      this.is_loading = false;
    }
  }

  verifyFfTheUserHasTheRecurringContributionActive = async () => {
    try {
      // eslint-disable-next-line max-len
      const { confirm_periodicity } = await this.get_allianz_account_query.execute({ customer_id: this.customer_id });
      this.has_active_recurrent_contribution = confirm_periodicity;
    } catch (error) {
      if (error.message !== this.error_allianz_account_not_exists) {
        this.message_notifier.showErrorNotification(this.translate('errors.get_allianz_account_query'));
      }
    }
  }

  upgradeToModerate = () => {
    this.view.$emit('upgradeToModerate', {
      investor_goal_id: this.retirement_investor_goal.investor_goal_id,
      investor_goal_name: this.translate('title'),
    });
  }

  modifyStrategy = (status: string) => {
    this.view.$emit('modifyStrategy', {
      investor_goal_id: this.retirement_investor_goal.investor_goal_id,
      investor_goal_name: this.translate('title'),
      status,
    });
  }

  showTransferStatusModerate = () => {
    this.view.$emit('showTransferStatusModerate', {
      investor_goal_id: this.retirement_investor_goal.investor_goal_id,
      investor_goal_name: this.translate('title'),
    });
  }

  loadProgressGoalInformation = (tracking_percent: number) => {
    this.tracking_percent = (tracking_percent > 100)
      ? 100 : Number(tracking_percent.toFixed(0));
  }

  setLinkedInvestorGoalInformation = () => {
    const state = this.linked_goal_state_manager_service.getLinkedGoalState();
    state.goal.investor_goal_id = this.retirement_investor_goal.investor_goal_id;
    state.goal.goal_name = this.translate('title');
    state.goal.goal_icon = 'icon-retirement.svg';
    state.goal.associated_product_id = this.retirement_investor_goal.associated_product_id;
  }

  endProcessWealthContribution = () => {
    this.show_contribute_dialog = false;
  }

  handleDistributeChange = async () => {
    await this.loadRetirementInvestorGoalInformation(true);
  }

  setSelectedInvestorGoalState = () => {
    this.manage_tracking_investor_goal_state.investor_goal_id = this.retirement_investor_goal
      .investor_goal_id;
    this.manage_tracking_investor_goal_state.investor_goal_name = this.translate('title');
    this.manage_tracking_investor_goal_state.strategy.name = this.retirement_investor_goal
      .strategy === 'pesos'
      ? this.translate('strategies.pesos') : this.translate('strategies.moderate');
    this.manage_tracking_investor_goal_state.strategy.tooltip = this.translate(
      `tooltips.${this.retirement_investor_goal.strategy}`,
    );
    this.manage_tracking_investor_goal_state.icon = {
      file_id: '',
      name: 'icon-retirement.svg',
      updated_at: 0,
    };
    this.manage_tracking_investor_goal_state.product_name = this.translate('description');
  }

  pauseTrackingGoal = async () => {
    this.setSelectedInvestorGoalState();
    this.manage_tracking_investor_goal_state.show_pause_tracking_dialog = true;
  }

  resumeTrackingGoal = async () => {
    this.setSelectedInvestorGoalState();
    this.manage_tracking_investor_goal_state.show_resume_tracking_dialog = true;
  }

  showGoalMovements = () => {
    this.setSelectedInvestorGoalState();
    this.manage_tracking_investor_goal_state.show_goal_movements = true;
  }

  handleShowPauseTrackingReasonDialog = () => {
    if (this.manage_tracking_investor_goal_state.investor_goal_id === this.retirement_investor_goal
      .investor_goal_id) {
      this.tracking_enabled = false;
    }
  }

  handleShowTrackingResumedDialog = () => {
    if (this.manage_tracking_investor_goal_state.investor_goal_id === this.retirement_investor_goal
      .investor_goal_id) {
      this.tracking_enabled = true;
    }
  }
}
