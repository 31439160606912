import TYPES from '@/types';
import { currencyFormat } from '@/vue-app/utils/currency';

import Vue from 'vue';

// Application
import GetCustomerInvestorGoalReminderQuery
  from '@/modules/flagship/customer-investor-goal-reminder/application/queries/get-customer-investor-goal-reminder-query';
import {
  GetAllianzAccountQuery,
} from '@/modules/my-investment/allianz/allianz-account/application/queries';
import LinkedGoalStateManagerService
  from '@/modules/flagship/investor-goal/linked-investor-goal/application/services/linked-goal-state-manager-service';
import GetGoalTrackingQuery
  from '@/modules/flagship/investor-goal/goal-tracking/application/queries/get-goal-tracking-query';
import GetInvestorGoalDetailQuery
  from '@/modules/flagship/investor-goal/investor-goal-detail/application/queries/get-investor-goal-detail-query';
import GetModerateIsContractedQuery
  from '@/modules/flagship/customer-investment-product-fund-type/application/queries/get-moderate-is-contracted-query';

// Domain
import {
  verifyIfInvestmentProductFundTypeIsAvailable,
} from '@/modules/flagship/customer-investment-product-fund-type/domain/services/verify-if-investment-product-fund-type-is-available';
import {
  ManageTrackingInvestorGoalStateManager,
} from '@/modules/flagship/investor-goal/investor_goal/domain/state/manage-tracking-investor-goal-state-manager';
import {
  CustomInvestorGoalEntity,
} from '@/modules/flagship/custom-investor-goal/domain/entities/custom-investor-goal-entity';
import {
  GoalTrackingEntity,
} from '@/modules/flagship/investor-goal/goal-tracking/domain/entities/goal-tracking-entity';
import Inject from '@/modules/shared/domain/di/inject';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';
import { DateFormatter } from '@/modules/shared/domain/date-formatters';

export default class LinkedCustomInvestorGoalCardViewModel {
  @Inject(TYPES.I18N)
  private readonly translator!: Translator;

  @Inject(TYPES.GET_CUSTOMER_INVESTOR_GOAL_REMINDER_QUERY)
  private readonly get_customer_investor_goal_reminder_query!: GetCustomerInvestorGoalReminderQuery;

  @Inject(TYPES.GET_ALLIANZ_ACCOUNT_QUERY)
  private readonly get_allianz_account_query!: GetAllianzAccountQuery;

  @Inject(TYPES.GET_GOAL_TRACKING_QUERY)
  private readonly get_goal_tracking_query!: GetGoalTrackingQuery;

  @Inject(TYPES.GET_INVESTOR_GOAL_DETAIL_QUERY)
  private readonly get_investor_goal_detail!: GetInvestorGoalDetailQuery;

  @Inject(TYPES.GET_MODERATE_IS_CONTRACTED_QUERY)
  private readonly get_moderate_is_contracted_query!: GetModerateIsContractedQuery;

  @Inject(TYPES.LINKED_GOAL_STATE_MANAGER_SERVICE)
  private readonly linked_goal_state_manager_service!:
    LinkedGoalStateManagerService;

  @Inject(TYPES.MANAGE_TRACKING_INVESTOR_GOAL_STATE_MANAGER)
  readonly manage_tracking_investor_goal_state_manager!: ManageTrackingInvestorGoalStateManager;

  @Inject(TYPES.NOTIFIER)
  private readonly message_notifier!: MessageNotifier;

  @Inject(TYPES.DATE_FORMATTER)
  readonly date_formatter!: DateFormatter;

  readonly i18n_namespace = 'components.goals-dashboard.linked-custom-investor-goal-card';

  private readonly customer_id = sessionStorage.getItem('user_id');

  private readonly error_allianz_account_not_exists = `Error customer_id:${this.customer_id}, not exists`;

  private readonly MODERATE_FUND_NAME = 'SWS-MODERADO';

  readonly limit_for_allow_stop_tracking = 40;

  readonly bucket_url = `${process.env.VUE_APP_S3_PUBLIC_URL}/investment/flagship/custom_investor_icons/`;

  is_loading = false;

  details_card = {
    is_open: true,
  };

  show_edit_name_goal_modal = false;

  show_edit_plan_dialog = false;

  show_choose_action_edit_goal_dialog = false;

  show_delete_custom_investor_goal_modal = false;

  show_recurring_deposit_dialog = false;

  custom_investor_goal = {
    id: '',
    is_active: false,
    custom_goal_name: '',
    custom_goal_icon: 'icon-house.svg',
    custom_goal_description: '',
    is_custom_goal_wealth: false,
    recurrent_assigned_amount: 0,
    desired_amount: 0,
    accumulated_amount: 0,
    active_goal_amount: 0,
    monthly_required_amount: 0,
    progress: 0,
    initial_investment_date: '',
    final_investment_date: '',
    strategy: 'pesos',
    investment_product_fund_types: {},
    last_edition_date: '',
    updated_at: '',
    associated_product_id: '',
    is_a_bucket: false,
  }

  show_details_modal = false;

  custom_goal_information = {
    investor_goal_id: '',
    is_custom_goal_pocket: false,
    name: '',
    icon_name: '',
    strategy_moderate_is_available: false,
    initial_amount: 0,
  }

  color_sws = {
    color_card: '',
    color_title: 'primary--text',
    color_subtitle: 'sky-blue--text',
    color_label: 'white--text',
    background_color_label: 'accent',
    color_divider: '',
    color_icon: 'sky-blue',
    color_btn: 'white--text',
    color_progress: 'accent',
  }

  show_delete_button = false;

  show_edit_button = true;

  show_modify_strategy_button = false;

  show_status_button = false;

  show_stop_tracking_button = false;

  associated_product_id = '';

  day_to_remind = 0;

  is_subscribed = false;

  has_active_recurrent_contribution = false;

  custom_icon_file_id = '';

  section_plan_dates_cols = 6;

  tracking_percent = 0;

  show_pocket_contribute_dialog = false;

  show_wealth_contribute_dialog = false;

  is_a_bucket_goal = false;

  monthly_collection_day = 0;

  show_transfer_status_dialog = false;

  show_contracting_status_moderate_dialog = false;

  contracting_status = '';

  goal_tracking: GoalTrackingEntity = {
    current_goal_balance: 0,
    expected_goal_balance: 0,
    goal_target_balance: 0,
    goal_tracking_percent: 0,
    earnings: 0,
  };

  tracking_enabled = false;

  manage_tracking_investor_goal_state = this
    .manage_tracking_investor_goal_state_manager.state;

  readonly view: Vue;

  constructor(view: Vue) {
    this.view = view;
  }

  get show_stop_tracking_goal() {
    return this.tracking_percent <= this.limit_for_allow_stop_tracking && this.tracking_enabled
      && !this.is_a_bucket_goal;
  }

  get show_resume_tracking_goal() {
    return !this.tracking_enabled;
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  showDetailsModal = () => {
    this.show_details_modal = true;
  }

  showContributeDialog = () => {
    if (this.custom_goal_information.is_custom_goal_pocket) {
      this.show_pocket_contribute_dialog = true;
    } else {
      this.setLinkedInvestorGoalInformation();
      this.show_wealth_contribute_dialog = true;
    }
  }

  initialize = async (custom_investor_goal: CustomInvestorGoalEntity) => {
    await this.loadCustomInvestorGoalInformation(custom_investor_goal);
    await this.validateIfIsABucketGoal(custom_investor_goal);
    await this.validateIfHasContractedModerate(custom_investor_goal);
  }

  get start_date_formatted() {
    return (this.custom_investor_goal.initial_investment_date)
      ? this.date_formatter.formatDate(this.custom_investor_goal.initial_investment_date, 'DD/MM/YYYY')
      : this.custom_investor_goal.initial_investment_date;
  }

  get final_date_formatted() {
    return (this.custom_investor_goal.final_investment_date)
      ? this.date_formatter.formatDate(this.custom_investor_goal.final_investment_date, 'DD/MM/YYYY')
      : this.custom_investor_goal.final_investment_date;
  }

  get last_edition_date_formatted() {
    return (this.custom_investor_goal.last_edition_date)
      ? this.date_formatter.formatDate(this.custom_investor_goal.last_edition_date, 'DD/MM/YYYY')
      : this.custom_investor_goal.last_edition_date;
  }

  getAmountFormatted(amount: number) {
    return `${currencyFormat(amount)} MXN`;
  }

  getWealthPocketBackgroundOrProgressCustomColor(is_goal_wealth: boolean) {
    return (is_goal_wealth) ? 'accent' : 'secondary';
  }

  showEditNameGoalModal = () => {
    this.show_edit_name_goal_modal = true;
    this.show_choose_action_edit_goal_dialog = false;
  }

  showEditPlanGoalDialog = () => {
    this.show_edit_plan_dialog = true;
    this.show_choose_action_edit_goal_dialog = false;
  }

  showChooseActionEditGoalDialog = () => {
    this.show_choose_action_edit_goal_dialog = true;
  }

  showDeleteCustomInvestorGoalModal = () => {
    this.show_delete_custom_investor_goal_modal = true;
  }

  showRecurringDepositDialog = () => {
    this.show_delete_custom_investor_goal_modal = false;
    this.show_recurring_deposit_dialog = true;
  }

  get progress_formatted() {
    return this.custom_investor_goal.progress.toFixed(2);
  }

  get custom_icon_class() {
    return (this.custom_investor_goal.custom_goal_icon === 'icon-add.svg' && this.custom_icon_file_id) ? 'custom-uploaded-img' : 'icon-max-width';
  }

  get icon_path() {
    // eslint-disable-next-line max-len
    // eslint-disable-next-line @typescript-eslint/no-var-requires,global-require,import/no-dynamic-require
    let icon = require(`@/assets/icons/custom-goal-icons/${this.custom_investor_goal.custom_goal_icon}`);
    if (this.custom_icon_file_id) {
      icon = `${this.bucket_url}${this.custom_icon_file_id}?${this.custom_goal_updated_at_time_stamp}`;
    }
    return icon;
  }

  get custom_goal_updated_at_time_stamp() {
    return new Date(this.custom_investor_goal.updated_at).getTime();
  }

  get strategy_label() {
    return this.custom_investor_goal.strategy === 'pesos' ? '' : this.translate('strategies.moderate');
  }

  validateIfHasContractedModerate = async (custom_investor_goal: CustomInvestorGoalEntity) => {
    const goal_is_linked_to_moderate = custom_investor_goal.current_strategy === 'SWSMOD';
    if (goal_is_linked_to_moderate) {
      this.custom_investor_goal.strategy = 'moderate';
      await this.loadGoalTracking(custom_investor_goal.investor_goal_id);
      this.changeColorSwsModerate();
      this.show_status_button = false;
      this.show_modify_strategy_button = true;
    } else {
      if (custom_investor_goal.is_a_bucket) {
        await this.loadGoalTracking(custom_investor_goal.investor_goal_id);
      }
      this.validateIfIsOnUpgradeProcess(custom_investor_goal);
    }
  }

  validateIfIsOnUpgradeProcess = (custom_investor_goal: CustomInvestorGoalEntity) => {
    if (!custom_investor_goal.strategy_change_status
      && !custom_investor_goal.strategy_hiring_status) {
      this.show_delete_button = true;
    }
  }

  loadCustomInvestorGoalInformation = async (custom_investor_goal: CustomInvestorGoalEntity) => {
    try {
      this.is_loading = true;
      this.custom_icon_file_id = custom_investor_goal.custom_icon_file_id || '';
      this.custom_goal_information.investor_goal_id = custom_investor_goal.investor_goal_id;
      this.custom_goal_information.is_custom_goal_pocket = custom_investor_goal.investor_goal.associated_product.name === 'sowos_pocket';
      this.custom_goal_information.name = custom_investor_goal
        .goal_name ? custom_investor_goal.goal_name : custom_investor_goal.custom_goal_type.label;
      this.custom_goal_information.icon_name = custom_investor_goal.custom_goal_type.icon_name;
      this.custom_goal_information.initial_amount = custom_investor_goal
        .investor_goal.initial_amount;
      this.custom_investor_goal.is_active = custom_investor_goal.investor_goal.is_active;
      this.custom_investor_goal.custom_goal_name = custom_investor_goal
        .goal_name ? custom_investor_goal.goal_name : custom_investor_goal.custom_goal_type.label;
      this.custom_investor_goal.custom_goal_icon = custom_investor_goal.custom_goal_type.icon_name;
      this.custom_investor_goal.associated_product_id = custom_investor_goal.investor_goal
        .associated_product_id;
      // eslint-disable-next-line max-len
      this.custom_investor_goal.custom_goal_description = (custom_investor_goal.investor_goal.associated_product.name === 'sowos_pocket')
        ? this.translate('description_pocket') : this.translate('description_wealth');
      // eslint-disable-next-line max-len
      this.custom_investor_goal.is_custom_goal_wealth = custom_investor_goal.investor_goal.associated_product.name === 'sowos_wealth';
      if (this.custom_investor_goal.is_custom_goal_wealth) {
        await this.verifyFfTheUserHasTheRecurringContributionActive();
        const available_investment_product_funds = custom_investor_goal
          .investor_goal.investment_product_fund_types.available;
        const moderate_available = verifyIfInvestmentProductFundTypeIsAvailable(
          available_investment_product_funds,
        );
        this.show_status_button = this.custom_investor_goal.is_a_bucket
          || moderate_available;
      } else {
        this.color_sws.background_color_label = 'secondary';
      }
      if (this.has_active_recurrent_contribution) {
        this.custom_investor_goal.recurrent_assigned_amount = custom_investor_goal
          .investor_goal.recurrent_assigned_amount || 0;
      }
      this.custom_investor_goal.desired_amount = Number((custom_investor_goal.desired_amount)
        ? custom_investor_goal.desired_amount : 0);
      // eslint-disable-next-line max-len
      this.custom_investor_goal.accumulated_amount = Math.round(custom_investor_goal.investor_goal.accumulated_amount);
      // eslint-disable-next-line max-len
      this.custom_investor_goal.active_goal_amount = custom_investor_goal.investor_goal.active_goal_amount!;
      // eslint-disable-next-line max-len
      this.custom_investor_goal.monthly_required_amount = custom_investor_goal.investor_goal.monthly_required_amount;
      // eslint-disable-next-line max-len
      this.custom_investor_goal.progress = (this.custom_investor_goal.active_goal_amount / this.custom_investor_goal.accumulated_amount) * 100;
      // eslint-disable-next-line max-len
      this.custom_investor_goal.initial_investment_date = custom_investor_goal.investor_goal.initial_investment_date;
      // eslint-disable-next-line max-len
      this.custom_investor_goal.final_investment_date = custom_investor_goal.investor_goal.final_investment_date;
      this.associated_product_id = custom_investor_goal.investor_goal.associated_product_id;
      if (!this.custom_investor_goal.is_custom_goal_wealth) {
        await this.loadCustomerInvestorGoalReminder();
      }
      this.custom_investor_goal.updated_at = custom_investor_goal.updated_at!;
      if (custom_investor_goal.first_initial_investment_date) {
        this.section_plan_dates_cols = 4;
        this.custom_investor_goal.initial_investment_date = custom_investor_goal
          .first_initial_investment_date;
        this.custom_investor_goal.last_edition_date = custom_investor_goal
          .investor_goal.initial_investment_date;
      }
      this.loadProgressGoalInformation(custom_investor_goal.tracking_percent);
      this.tracking_enabled = custom_investor_goal.investor_goal.tracking_enabled;
    } catch (error) {
      this.message_notifier.showErrorNotification(this.translate('errors.get_customer_investor_goal_reminder'));
    } finally {
      this.is_loading = false;
    }
  }

  loadGoalTracking = async (investor_goal_id: string) => {
    try {
      this.goal_tracking = await this.get_goal_tracking_query.execute(investor_goal_id);
    } catch (error) {
      if (JSON.parse(error).status_code !== 404) {
        this.message_notifier.showErrorNotification(this.translate('errors.load_progress_goal_information'));
      }
    }
  }

  validateIfIsABucketGoal = async (custom_investor_goal: CustomInvestorGoalEntity) => {
    this.is_a_bucket_goal = custom_investor_goal.is_a_bucket;
  }

  loadCustomerInvestorGoalReminder = async () => {
    try {
      // eslint-disable-next-line max-len
      const customer_investor_goal_reminder = await this.get_customer_investor_goal_reminder_query.execute({
        associated_product_id: this.associated_product_id,
      });
      if (customer_investor_goal_reminder) {
        this.day_to_remind = customer_investor_goal_reminder.day_to_remind;
        this.is_subscribed = customer_investor_goal_reminder.is_subscribed || false;
      }
    } catch (error) {
      if (JSON.parse(error).status_code !== 404) {
        this.message_notifier.showErrorNotification(this.translate('errors.get_customer_investor_goal_reminder'));
      }
    }
  };

  verifyFfTheUserHasTheRecurringContributionActive = async () => {
    try {
      const { home_desire, monthly_collection_day } = await this
        .get_allianz_account_query.execute({ customer_id: this.customer_id });
      if (home_desire) {
        this.has_active_recurrent_contribution = true;
        this.monthly_collection_day = monthly_collection_day || 0;
      }
    } catch (error) {
      if (error.message !== this.error_allianz_account_not_exists) {
        this.message_notifier.showErrorNotification(this.translate('errors.get_allianz_account_query'));
      }
    }
  }

  upgradeToModerate = () => {
    this.view.$emit('upgradeToModerate', {
      investor_goal_id: this.custom_goal_information.investor_goal_id,
      investor_goal_name: this.custom_goal_information.name,
    });
  }

  modifyStrategy = (status: string) => {
    this.view.$emit('modifyStrategy', {
      investor_goal_id: this.custom_goal_information.investor_goal_id,
      investor_goal_name: this.custom_goal_information.name,
      status,
    });
  }

  loadProgressGoalInformation = (tracking_percent: number) => {
    this.tracking_percent = (tracking_percent > 100)
      ? 100 : Number(tracking_percent.toFixed(0));
    this.show_stop_tracking_button = this.tracking_percent < this.limit_for_allow_stop_tracking;
  }

  setLinkedInvestorGoalInformation = () => {
    const state = this.linked_goal_state_manager_service.getLinkedGoalState();
    state.goal.investor_goal_id = this.custom_goal_information.investor_goal_id;
    state.goal.goal_name = this.custom_investor_goal.custom_goal_name;
    state.goal.goal_icon = this.custom_investor_goal.custom_goal_icon;
    state.goal.associated_product_id = this.custom_investor_goal.associated_product_id;
    state.goal.custom_icon_file_id = this.custom_icon_file_id;
  }

  showStatus = async (custom_investor_goal: CustomInvestorGoalEntity) => {
    const { strategy_hiring_status, strategy_change_status } = custom_investor_goal;
    if (strategy_hiring_status && strategy_hiring_status !== 'contracted') {
      this.show_contracting_status_moderate_dialog = true;
      this.contracting_status = strategy_hiring_status;
    } else if (strategy_change_status && strategy_change_status !== 'linked') {
      this.show_transfer_status_dialog = true;
      this.contracting_status = strategy_change_status;
    }
  }

  changeColorSwsModerate = () => {
    this.color_sws.color_card = 'primary';
    this.color_sws.color_title = 'white--text';
    this.color_sws.color_subtitle = 'white--text';
    this.color_sws.color_label = 'primary--text';
    this.color_sws.background_color_label = 'white';
    this.color_sws.color_divider = 'white';
    this.color_sws.color_icon = 'white';
    this.color_sws.color_btn = 'accent--text';
    this.color_sws.color_progress = 'white';
  }

  closeModalPocketContribute = () => {
    this.show_pocket_contribute_dialog = false;
  }

  endProcessWealthContribution = () => {
    this.show_wealth_contribute_dialog = false;
  }

  showModifyStrategyFlow = () => {
    this.modifyStrategy('');
  }

  informationUpdated = () => {
    this.view.$emit('informationUpdated');
  }

  pauseTrackingGoal = async () => {
    this.manage_tracking_investor_goal_state.investor_goal_id = this.custom_goal_information
      .investor_goal_id;
    this.manage_tracking_investor_goal_state.investor_goal_name = this.custom_goal_information.name;
    this.manage_tracking_investor_goal_state.show_pause_tracking_dialog = true;
  }

  setSelectedInvestorGoalState = () => {
    this.manage_tracking_investor_goal_state.investor_goal_id = this.custom_goal_information
      .investor_goal_id;
    this.manage_tracking_investor_goal_state.investor_goal_name = this.custom_goal_information.name;
    this.manage_tracking_investor_goal_state.strategy.name = this.custom_investor_goal
      .strategy === 'pesos'
      ? this.translate('strategies.pesos') : this.translate('strategies.moderate');
    this.manage_tracking_investor_goal_state.strategy.tooltip = this.translate(
      `tooltips.${this.custom_investor_goal.strategy}`,
    );
    this.manage_tracking_investor_goal_state.icon = {
      file_id: this.custom_icon_file_id,
      name: this.custom_investor_goal.custom_goal_icon,
      updated_at: this.custom_goal_updated_at_time_stamp,
    };
    this.manage_tracking_investor_goal_state.product_name = this.custom_investor_goal
      .custom_goal_description;
  }

  resumeTrackingGoal = async () => {
    this.setSelectedInvestorGoalState();
    this.manage_tracking_investor_goal_state.show_resume_tracking_dialog = true;
  }

  showGoalMovements = () => {
    this.setSelectedInvestorGoalState();
    this.manage_tracking_investor_goal_state.show_goal_movements = true;
  }

  handleShowPauseTrackingReasonDialog = () => {
    if (this.manage_tracking_investor_goal_state.investor_goal_id === this.custom_goal_information
      .investor_goal_id) {
      this.tracking_enabled = false;
    }
  }

  handleShowTrackingResumedDialog = () => {
    if (this.manage_tracking_investor_goal_state.investor_goal_id === this.custom_goal_information
      .investor_goal_id) {
      this.tracking_enabled = true;
    }
  }
}
